import React, { useContext, useState } from "react";
import { Button, ButtonGroup, Container, Typography } from "@material-ui/core";
import { kgToLbs, displayWeight } from "../logic/units";
import UnitContext from "../contexts/UnitContext";
import BarAndCollarContext from "../contexts/BarAndCollarContext";

const CollarWeightInput = () => {
  const { unit } = useContext(UnitContext);
  const { collarWeight, setCollarWeight } = useContext(BarAndCollarContext);

  const [variantCollar, setVariantCollar] = useState("contained");
  const [variantNoCollar, setVariantNoCollar] = useState("outlined");

  const displayCollarWeight = unit === "kg" ? 2.5 : displayWeight(kgToLbs(2.5));

  const changeCollar = (weight) => {
    setCollarWeight(weight);
    if(weight === 0) {
      setVariantNoCollar("contained");
      setVariantCollar("outlined");
    } else {
      setVariantNoCollar("outlined");
      setVariantCollar("contained");
    }
  }

  return (
    <Container>
      <Typography variant="subtitle1">Collar weight</Typography>
      <ButtonGroup color="primary" aria-label="outlined button group">
        <Button onClick={() => changeCollar(0)} active={collarWeight === 0} variant={variantNoCollar}>
          <span>0{unit}</span>
        </Button>
        <Button
          onClick={() => changeCollar(displayCollarWeight)}
          active={collarWeight > 0}
          variant={variantCollar}
        >
          <span>{displayCollarWeight}</span>
          <span>{unit}</span>
        </Button>
      </ButtonGroup>
    </Container>
  );
};

export default CollarWeightInput;
