import React, { useContext, useState } from "react";
import { Button, ButtonGroup, Container, Typography } from "@material-ui/core";
import RoundingContext from "../contexts/RoundingContext";

const RoundingInput = () => {
  const { rounding, setRounding } = useContext(RoundingContext);

  const [variantNearest, setVariantNearest] = useState("contained");
  const [variantUp, setVariantUp] = useState("outlined");
  const [variantDown, setVariantDown] = useState("outlined");

  const changeRounding = (type) => {
    setRounding(type);
    if (type === "nearest") {
      setVariantNearest("contained");
      setVariantUp("outlined");
      setVariantDown("outlined");
    } else if (type === "up") {
      setVariantNearest("outlined");
      setVariantUp("contained");
      setVariantDown("outlined");
    } else {
      setVariantNearest("outlined");
      setVariantUp("outlined");
      setVariantDown("contained");
    }

  }

  return (
    <Container>
      <Typography variant="subtitle1">Rounding</Typography>
      <ButtonGroup color="primary" aria-label="button group">
        <Button
          onClick={() => changeRounding("nearest")}
          active={rounding === "nearest"}
          variant={variantNearest}
        >
          nearest
        </Button>
        <Button
          onClick={() => changeRounding("up")}
          active={rounding === "up"}
          variant={variantUp}>
          up
        </Button>
        <Button
          onClick={() => changeRounding("down")}
          active={rounding === "down"}
          variant={variantDown}
        >
          down
        </Button>
      </ButtonGroup>
    </Container>
  );
};

export default RoundingInput;
