import React, { useContext, useState } from "react";
import { Button, ButtonGroup, Container, RadioGroup, Typography } from "@material-ui/core";
import UnitContext from "../contexts/UnitContext";
import BarAndCollarContext from "../contexts/BarAndCollarContext";

const UnitInput = () => {
  const { unit, setUnit } = useContext(UnitContext);
  const { setBarWeight, setCollarWeight } = useContext(BarAndCollarContext);
  const [ variantKg, setVariantKg ] = useState("contained");
  const [ variantLbs, setVariantLbs ] = useState("outlined");

  const updateUnit = unit => {
    console.log(variantKg);

    if(unit === "kg") {
      setVariantKg("contained");
      setVariantLbs("outlined");
    } else {
      setVariantKg("outlined");
      setVariantLbs("contained");
    }

    setUnit(unit);
    const newBarWeight = unit === "kg" ? 20 : 45;
    setBarWeight(newBarWeight);
    const newCollarWeight = unit === "kg" ? 2.5 : 0;
    setCollarWeight(newCollarWeight);
  };

  return (
    <Container>
      <Typography variant="subtitle1">Unit</Typography>

      <ButtonGroup color="primary" aria-label="button group">
        <Button onClick={() => updateUnit("kg")} active={unit === "kg"} variant={variantKg}>
          Kilos
        </Button>
        <Button onClick={() => updateUnit("lbs")} active={unit === "lbs"} variant={variantLbs}>
          Pounds
        </Button>
      </ButtonGroup>
    </Container>
  );
};

export default UnitInput;
